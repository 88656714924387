import React, { Component } from "react";

import { connect } from "react-redux";
import { logout } from "../../store/actions/auth";
import { ReactComponent as Gear } from "./gear.svg";
import { withTranslation } from "react-i18next";
import LinkedInTag from "react-linkedin-insight";
import ReactPixel from "react-facebook-pixel";
import Packages from "../Packages/Packages";
import ReactGA from "react-ga";
class StoreInfo extends Component {
  state = {};
  componentDidMount = () => {
    if (!this.props.user) return;
    setTimeout(() => {
      window.location.href = this.props.dashURL;
      this.props.setDidFinish();
      this.props.logout();
    }, 5000);
    LinkedInTag.track("6807255");
    const { user } = this.props;

    const packageName = Packages[user.country][user.package].name;
    const price = Packages[user.country][user.package][
      `${user.term}Price`
    ].replace(/[^0-9]/g, "");
    const isos = {
      Kuwait: "KWD",
      Bahrain: "BHD",

      "Saudi Arabia": "SAR",
    };
    ReactPixel.track("Purchase", {
      currency: isos[user.country],
      value: price,
      event_id: user.user_id,
    });
    ReactGA.event({
      category: `Subscription`,
      action: "Customer created a store",
      label: `${packageName} - ${this.props.dashURL}`,
      value: price,
    });
  };
  render() {
    const { t } = this.props;
    return (
      <div className="my-4">
        <h4 className="mt-4" style={{ marginBottom: 30 }}>
          {t("Please hold while we build your website")}
        </h4>

        <div
          className="mx-auto"
          style={{ maxWidth: 331, width: "calc(100% - 66px)" }}
        >
          <Gear style={{ width: 200, height: 200 }} />
          <p className="mt-4">
            {t(
              "This should take a few moments. You will be redirected to your dashboard once this is done"
            )}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registrationData: state.registrationData,
    user: state.auth.user,
    country: state.auth.country,
    config: state.config,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    setDidFinish: () => dispatch({ type: "DID_FINISH_SIGN_UP" }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StoreInfo));
