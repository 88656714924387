import { SWITCH_LANGUAGE } from "./actionTypes";
import i18n from "../../i18n";

export const switchLanguage = (language) => {
  return function (dispatch, getState) {
    i18n.changeLanguage(language);
    try {
      const profile = getState().profile;
      if (profile.key) {
        let email = (profile.email || "").trim() || getState().auth.username;

        if (window.Tawk_API?.setAttributes)
          window.Tawk_API.setAttributes(
            {
              name: getState().auth.username,
              language: language,
              email: email,
              hash: profile.key,
            },
            (error) => {
              if (error) console.log(error);
            }
          );
      }
    } catch (error) {
      console.log(error);
    }
    window.location.reload();

    dispatch({
      type: SWITCH_LANGUAGE,
      payload: language,
    });
  };
};
