import React, { Component } from "react";
import { Select, Space, Button, Input, Spin, message } from "antd";

import {
  LeftOutlined,
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { goBack, saveStepInfo } from "../../store/actions/registrationData";
import { updateUser, verifyAccount } from "../../store/actions/auth";
import instance from "../../store/actions/instance";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { Redirect } from "react-router-dom";
import { ReactComponent as Tick } from "../assets/tick.svg";
import { withTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const validateSubdomain = (val) =>
  instance.get(`/check_subdomain_availability/${val}/`);
const searchDebounced = AwesomeDebouncePromise(validateSubdomain, 500);

const { Option } = Select;

class StoreInfo extends Component {
  state = {
    phoneCode: "",
    emailCode: "",

    phoneTimer: null,
    emailTimer: null,

    phoneResendLoading: false,
    emailResendLoading: false,
  };

  phoneInterval = null;
  emailInterval = null;

  continueButtonDisabled = () => {
    const { user } = this.props;

    if (
      user.email_verified &&
      (user.phone_verified || user.phone_resend_count >= 1)
    )
      return false;
    if (
      user.phone_verified &&
      (user.email_verified || user.email_resend_count >= 1)
    )
      return false;
    return true;
  };

  renderContinueButtonText = () => {
    const { user, t } = this.props;
    if (
      user.email_verified &&
      !user.phone_verified &&
      user.phone_resend_count >= 1
    )
      return t("Verify phone later");
    if (
      user.phone_verified &&
      !user.email_verified &&
      user.email_resend_count >= 1
    )
      return t("Verify email later");
    return t("CONTINUE");
  };

  renderPhoneResendButton = () => {
    const { t } = this.props;
    if (this.state.phoneResendLoading)
      return <span style={{ color: "#0099cc" }}>{antIcon}</span>;
    if (this.state.phoneTimer) {
      if (this.state.phoneTimer === 60) return "01:00";
      else if (this.state.phoneTimer < 10)
        return "00:0".concat(this.state.phoneTimer);
      return "00:".concat(this.state.phoneTimer);
    }
    return t("Resend");
  };

  renderEmailResendButton = () => {
    const { t } = this.props;
    if (this.state.emailResendLoading)
      return <span style={{ color: "#0099cc" }}>{antIcon}</span>;
    if (this.state.emailTimer) {
      if (this.state.emailTimer === 60) return "01:00";
      else if (this.state.emailTimer < 10)
        return "00:0".concat(this.state.emailTimer);
      return "00:".concat(this.state.emailTimer);
    }
    return t("Resend");
  };

  startPhoneTimer = () => {
    if (this.state.phoneTimer > 0 || this.phoneInterval) return;
    this.setState({ phoneTimer: this.props.user.last_sms || 60 });
    this.phoneInterval = window.setInterval(() => {
      if (this.state.phoneTimer === 0) {
        window.clearInterval(this.phoneInterval);
        this.phoneInterval = null;
        this.setState({ phoneTimer: null });
      } else {
        this.setState({ phoneTimer: this.state.phoneTimer - 1 });
      }
    }, 1000);
  };
  onResendPhone = () => {
    if (
      this.state.phoneTimer > 0 ||
      this.phoneInterval ||
      this.state.phoneResendLoading
    )
      return;
    this.resendVerificationCode("phone");
  };

  startEmailTimer = () => {
    if (this.state.emailTimer > 0 || this.emailInterval) return;
    this.setState({ emailTimer: this.props.user.last_email || 60 });
    this.emailInterval = window.setInterval(() => {
      if (this.state.emailTimer === 0) {
        window.clearInterval(this.emailInterval);
        this.emailInterval = null;
        this.setState({ emailTimer: null });
      } else this.setState({ emailTimer: this.state.emailTimer - 1 });
    }, 1000);
  };
  onResendEmail = () => {
    if (
      this.state.emailTimer > 0 ||
      this.emailInterval ||
      this.state.emailResendLoading
    )
      return;
    this.resendVerificationCode("email");
  };

  componentWillUnmount = () => {
    window.clearInterval(this.phoneInterval);
    window.clearInterval(this.emailInterval);
  };

  resendVerificationCode = async (type) => {
    this.setState({ [`${type}ResendLoading`]: true });
    try {
      const res = await instance.post("/resend_verification_code/", {
        id: 1,
        type,
      });
      if (res.data.success) {
        this.props.updateUser(res.data.user);
        if (type === "phone") this.startPhoneTimer();
        else if (type === "email") this.startEmailTimer();
      } else message.error(res.data.message || "Something went wrong");
    } catch (e) {
      message.error("Something went wrong");
    }
    this.setState({ [`${type}ResendLoading`]: false });
  };
  componentDidMount = () => {
    const { user } = this.props;
    if (!user) return;
    if (user.last_sms) {
      this.startPhoneTimer();
    }
    if (user.last_email) this.startEmailTimer();
    ReactPixel.trackCustom("VerifyPage");

    ReactGA.event({
      category: "Signup flow",
      action: "Customer viewed the verify page",
    });
  };

  verifySubmit = async (code, field) => {
    try {
      const res = await instance.post("/go/verify_user/", {
        [`${field}Code`]: code,
      });
      if (res.data.success) {
        this.props.updateUser(res.data.user);
      } else message.error(res.data.message || "Something went wrong");
    } catch (e) {
      console.error(e);
      message.error("Something went wrong");
    }
  };

  onChangeField = (e) => {
    const { user } = this.props;
    this.setState({ [`${e.target.name}Code`]: e.target.value });
    if (
      e.target.name === "email" &&
      !user.email_verified &&
      e.target.value?.trim()?.length === 6
    ) {
      this.verifySubmit(e.target.value, "email");
    } else if (
      e.target.name === "phone" &&
      !user.phone_verified &&
      e.target.value?.trim()?.length === 6
    ) {
      this.verifySubmit(e.target.value, "phone");
    }
  };

  render() {
    const { user, config, t } = this.props;
    if (!user) return <Redirect to="/signup" />;
    const flagStyle = { width: 20, height: 20, marginRight: 10 };
    const inputStyle = {
      paddingTop: 13,
      paddingBottom: 13,
    };
    const resendButtonsStyle = {
      border: "1px solid #d9d9d9",
      fontWeight: "bold",
      display: "grid",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      color: "#0099cc",
    };
    return (
      <div className="my-4">
        <h4 className="mt-4" style={{ marginBottom: 40 }}>
          {t("Security verification")}
        </h4>
        <p>{t("Please complete the following verification")}</p>
        <div
          className="mx-auto"
          style={{ maxWidth: 331, width: "calc(100% - 66px)" }}
        >
          <Space direction="vertical" style={{ width: "100%" }} size="middle">
            {user.phone_verified ? (
              <div
                className="my-3"
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% auto",
                  alignItems: "center",
                }}
              >
                <Tick style={{ height: 30, width: 30 }} />
                <p
                  className={"m-0 ".concat(
                    config.language === "arabic" ? "text-right" : "text-left"
                  )}
                  style={{ direction: "ltr" }}
                >
                  <span style={{ fontWeight: "bold" }}>{user.phone}</span>{" "}
                  {t("is verified")}
                </p>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "70% 30%",
                    columnGap: 8,
                  }}
                >
                  <Input
                    placeholder={t("Phone verification code")}
                    size={"large"}
                    style={inputStyle}
                    onChange={this.onChangeField}
                    name="phone"
                  />
                  <div
                    style={{
                      ...resendButtonsStyle,
                      color: this.state.phoneTimer ? "grey" : "#0099cc",
                      cursor: this.state.phoneTimer ? "not-allowed" : "pointer",
                    }}
                    onClick={() => this.onResendPhone()}
                  >
                    {this.renderPhoneResendButton()}
                  </div>
                </div>
                <p
                  className={`my-1 ${
                    config.direction === "rtl" ? "text-right" : "text-left"
                  }`}
                  style={{ color: "#828282", fontSize: 12 }}
                >
                  {t("Enter the 6 digit code sent to")}{" "}
                  <span style={{ fontWeight: "bold" }}>{user.phone}</span>
                </p>
              </div>
            )}

            {user.email_verified ? (
              <div
                className="my-3"
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% auto",
                  alignItems: "center",
                }}
              >
                <Tick style={{ height: 30, width: 30 }} />
                <p
                  className={"m-0 ".concat(
                    config.language === "arabic" ? "text-right" : "text-left"
                  )}
                  style={{ direction: "ltr" }}
                >
                  <span style={{ fontWeight: "bold" }}>{user.email}</span>{" "}
                  {t("is verified")}
                </p>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "70% 30%",
                    columnGap: 8,
                  }}
                >
                  <Input
                    placeholder={t("E-mail verification code")}
                    size={"large"}
                    style={inputStyle}
                    onChange={this.onChangeField}
                    name="email"
                  />
                  <div
                    style={{
                      ...resendButtonsStyle,
                      color: this.state.emailTimer ? "grey" : "#0099cc",
                      cursor: this.state.emailTimer ? "not-allowed" : "pointer",
                    }}
                    onClick={() => this.onResendEmail()}
                  >
                    {this.renderEmailResendButton()}
                  </div>
                </div>
                <p
                  className={"my-1 ".concat(
                    config.language === "arabic" ? "text-right" : "text-left"
                  )}
                  style={{ color: "#828282", fontSize: 12 }}
                >
                  {t("Enter the 6 digit code sent to")}{" "}
                  <span style={{ fontWeight: "bold" }}>{user.email}</span>
                </p>
              </div>
            )}
          </Space>
          <Button
            className="py-3 mb-4 w-100"
            style={{
              backgroundColor: this.continueButtonDisabled()
                ? "#ebebeb"
                : "#0099cc",
              color: this.continueButtonDisabled() ? "#d6d6d6" : "white",
              height: 60,
              border: "none",
              marginTop: 30,
            }}
            disabled={this.continueButtonDisabled()}
            onClick={() => {
              this.props.completeVerification();
            }}
          >
            {this.renderContinueButtonText()}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    config: state.config,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveStepInfo: (data) => dispatch(saveStepInfo(data)),
    goBack: () => dispatch(goBack()),
    verifyAccount: () => dispatch(verifyAccount()),
    updateUser: (user) => dispatch(updateUser(user)),
    completeVerification: () => dispatch({ type: "COMPLETE_VERIFICATION" }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StoreInfo));
