import React, { useEffect } from "react";
import { ReactComponent as Success } from "../assets/success.svg";
import { Button } from "antd";

export default function SuccessPage() {
  return (
    <div className="mt-4">
      <Success style={{ width: 120, height: 120 }} />
      <h4 className="mt-5 mb-5">Your store was created successfully!</h4>
      <p>
        Your store might take up to <strong>10 minutes</strong> to get ready, so
        please be patient
      </p>
      <p>
        Meanwhile, you can log into your dashboard and start setting up your
        store
      </p>

      <a href="https://go.ordable.com/manage" target="blank">
        <Button
          type="primary"
          className="mt-4"
          style={{
            height: 60,
            width: 300,
            backgroundColor: "#0099cc",
            borderColor: "#0099cc",
          }}
        >
          Dashboard Login
        </Button>
      </a>

      <h4 className="mt-5">Enjoy!</h4>
    </div>
  );
}
