import React, { Component } from "react";
import { Modal } from "antd";

export default class Terms extends Component {
  render() {
    return (
      <Modal
        title={<></>}
        footer={<></>}
        visible={true}
        onCancel={() => this.props.onClose()}
        width={600}
        className="packageDetailModal"
        style={{ borderRadius: 10 }}
      >
        <div className="bold underline center mb-3">
          The Rights of First Party
        </div>
        <div>
          <span className="bold">
            1. Confidentiality, Privacy, and Data Protection
          </span>
          : The Second Party agrees to protect the First Party’s information
          including but not limited to services, interface, design, contract,
          pricing from its competitors.
        </div>
        <div>
          <span className="bold">2. Termination</span>: The 1st Party retains
          the right to terminate or suspend its service. Unless immediate action
          is required, the 1st Party will provide 30 days’ notice.
        </div>
        <div>
          <span className="bold">3. Limit of Liability</span>: The First Party
          is not liable for any errors, omissions or service delays.
        </div>
        <div>
          <span className="bold">4. Maintenance</span>: The First party retains
          the right to maintain the service without liability for service
          outages for any reason during the maintenance processes.
        </div>
        <div>
          <span className="bold">5. Copyrights and Advertising</span>: The First
          Party has the right to include the Second Party’s company name, brand,
          logo and other assets in its own marketing activities.
        </div>
        <div>
          <span className="bold">
            6. Notification, Communication and Service Levels
          </span>
          : The First Party commits to answering any inquiries or notification
          if made to info@ordable.com. Depending on the type of request, the
          First Party will respond with its ability to fulfill the request.
          Inquiries will be deemed to be duly given upon confirmation of
          receipt.
        </div>
        <div className="bold underline center my-3">
          The Rights of the Second Party
        </div>
        <div>
          <span className="bold">1. Services Provided</span>: The Service
          provided is a web-store that the 2nd Party can display and sell
          products/services to the 2nd Party’s customers. The 2nd Party retains
          the right to use or not to use the services purchased or provided for
          free for the duration of this contract.
        </div>
        <div>
          <span className="bold">2. Confidentiality</span>: The First Party will
          protect the confidentiality of the Second Party and their customers’
          data.
        </div>
        <div>
          <span className="bold">3.Termination of Service</span>: The Second
          Party retains the right to terminate the agreement with 30 days’
          notice but shall close any commitments to the 1st Party in full.{" "}
        </div>
        <div>
          <span className="bold">4. Limit of Liability</span>: The Second
          Party’s use of the First Party’s services is at the Second Party’s own
          risk.{" "}
        </div>
        <div>
          <span className="bold">5. Complete Information</span>: The Second
          Party is responsible for providing complete and correct information at
          all times and is responsible for any liabilities resulting from
          incorrect or incomplete information.{" "}
        </div>
        <div>
          <span className="bold">6. Third Party Providers</span>: The Second
          Party may use services through third parties - e.g. payments or
          delivery providers. The Second Party uses these services at The Second
          Party’s own risk.{" "}
        </div>
        <div>
          <span className="bold">7. Dispute resolution</span>: This Agreement
          shall be governed by and construed in accordance with the laws of the
          State of Kuwait. In case of any discrepancies in the interpretation of
          the terms of this agreement, the Arabic version shall prevail.{" "}
        </div>
        <div>
          <span className="bold">8. Acknowledgement</span>: The Second Party
          acknowledges that it has read all terms and conditions in this
          contract and accepts them fully upon signing this contract
        </div>
      </Modal>
    );
  }
}
