import {
  GO_BACK,
  SAVE_STEP_INFO,
  TOGGLE_CREATION_LOADING,
} from "./actionTypes";
import instance from "./instance";
import { message } from "antd";

export const saveStepInfo = (data) => {
  return {
    type: SAVE_STEP_INFO,
    payload: data,
  };
};

export const goBack = () => {
  return {
    type: GO_BACK,
  };
};

export const createStore = (data) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_CREATION_LOADING });
    try {
      let res = await instance.post("/create_store/", data);
      if (res.data.success) {
        dispatch({
          type: SAVE_STEP_INFO,
          payload: {},
        });
      } else message.error(res.data?.message || "Something went wrong");
    } catch (err) {
      console.error(err);
    }
    dispatch({ type: TOGGLE_CREATION_LOADING });
  };
};
