import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "antd/dist/antd.css";
import { checkForExpiredToken } from "./store/actions/auth";
import axios from "axios";
import Packages from "./components/Packages/Packages";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
ReactPixel.init("517170235750060");
ReactGA.initialize("UA-154901951-1");
ReactPixel.pageView();
ReactPixel.track("ViewContent");
ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.event({
  category: "View Content",
  action: `View Content`,
  label: "View Content",
});
store.dispatch(checkForExpiredToken());

async function getLocation() {
  try {
    const res = await axios.get("https://extreme-ip-lookup.com/json");
    if (Packages[res.data.country])
      store.dispatch({
        type: "SET_COUNTRY",
        payload: { country: res.data.country, new: true },
      });
    else
      store.dispatch({
        type: "SET_COUNTRY",
        payload: { country: "Kuwait", new: true },
      });
  } catch (e) {
    console.error(e);
    store.dispatch({
      type: "SET_COUNTRY",
      payload: { country: "Kuwait", new: true },
    });
  }
}
getLocation();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
