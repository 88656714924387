import {
  SAVE_STEP_INFO,
  GO_BACK,
  TOGGLE_CREATION_LOADING,
} from "../actions/actionTypes";

const initialState = {
  currentStep: 0,

  // Step 1
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phone: "",
  emailLoading: false,
  emailValid: false,
  passwordValid: false,

  // Step 2
  storeCategory: "",
  country: "",
  subdomain: "",
  storeName: "",
  domainValid: false,
  domainLoading: false,
  term: "yearly",

  registrationLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVE_STEP_INFO:
      return { ...state, ...payload, currentStep: state.currentStep + 1 };

    case GO_BACK:
      return { ...state, currentStep: state.currentStep - 1 };

    case TOGGLE_CREATION_LOADING:
      return { ...state, registrationLoading: !state.registrationLoading };

    default:
      return state;
  }
};
