import "./App.css";
import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { Spin } from "antd";
import { connect } from "react-redux";
import {
  LoadingOutlined,
  DeleteOutlined,
  PlusOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { logout } from "./store/actions/auth";
import SignUp from "./components/SignUp";
import Verification from "./components/Verification";
import StoreInfo from "./components/StoreInfo";
import SuccessPage from "./components/SuccessPage";
import Packages from "./components/NewPackages";
import { Steps } from "antd";
import PackagesDict from "./components/Packages/Packages";
import { ConfigProvider } from "antd";
import ArabicLocale from "antd/es/locale/ar_EG";
import EnglishLocale from "antd/es/locale/en_US";
import { switchLanguage } from "./store/actions/config";
import { withTranslation } from "react-i18next";
import LinkedInTag from "react-linkedin-insight";
const { Step } = Steps;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class App extends Component {
  renderRoutes = () => {
    const {
      user,
      selectedPackage,
      stayOnVerificationPage,
      history,
      finishedSignUp,
    } = this.props;

    if (finishedSignUp) {
      return [
        <Route path="/package" component={Packages} />,
        <Route path="/info" component={StoreInfo} />,
        <Redirect from="/" to="/info" />,
      ];
    }
    if (!user && selectedPackage === null) {
      return [
        <Route path="/package" component={Packages} />,
        <Route path="/login" component={SignUp} />,
        <Redirect from="/" to="/package" />,
      ];
    } else if (!user) {
      return [
        <Route path="/package" component={Packages} />,
        <Route path="/signup" component={SignUp} />,
        <Route path="/login" component={SignUp} />,
        <Redirect from="/" to="/signup" />,
      ];
    }
    if (!user.is_verified || stayOnVerificationPage) {
      return [
        <Route path="/package" component={Packages} />,
        <Route path="/verify" component={Verification} />,
        <Redirect from="/" to="/verify" />,
      ];
    } else if (user.is_verified && !user.did_create_store) {
      return [
        <Route path="/package" component={Packages} />,
        <Route path="/info" component={StoreInfo} />,
        <Redirect from="/" to="/info" />,
      ];
    } else {
      return [
        <Route path="/success" component={SuccessPage} />,
        <Redirect from="/" to="/success" />,
      ];
    }
  };

  renderPackageDescription = () => {
    const { selectedPackage, country } = this.props;
    if (selectedPackage === null) return undefined;
    const pkg = PackagesDict[country][selectedPackage];
    return `${pkg.price} ${pkg.term}ly`;

    return undefined;
  };

  componentDidMount = () => {
    const params = new URLSearchParams(window.location.search);
    let lang = params.get("lang");
    if (lang) this.props.history.replace("/package");
    if (lang === "ar") {
      this.props.switchLanguage("arabic");
    }
    if (lang === "en") this.props.switchLanguage("english");

    LinkedInTag.init("3278522", "dc", false);
  };
  findCurrentStep = () => {
    const { pathname } = this.props.history.location;
    switch (pathname) {
      case "/":
        return 0;
      case "/package":
        return 0;
      case "/signup":
        return 1;
      case "/verify":
        return 2;
      case "/info":
        return 3;
      default:
        return 0;
    }
  };
  shouldRenderStepper = () => {
    const { pathname } = this.props.history.location;
    const paths = ["/package", "/signup", "/verify", "/info"];
    return paths.includes(pathname);
  };

  getUsername = () => {
    const { user } = this.props;
    try {
      return user.name.split(" ")[0];
    } catch (e) {
      return "";
    }
  };
  render() {
    const { loading, user, config, t } = this.props;
    const loadingSpinner = (
      <div className="my-4 text-center">
        <Spin indicator={antIcon} />
      </div>
    );
    return (
      <ConfigProvider
        direction={config.direction}
        locale={config.language == "arabic" ? ArabicLocale : EnglishLocale}
      >
        <div className="pb-5">
          <div className="text-center mt-3">
            {!!user && (
              <p
                style={{
                  position: "absolute",
                  top: 25,
                  right: 100,
                  fontWeight: "bold",
                }}
              >
                <span className="usernameName">{this.getUsername()}</span>{" "}
                <LogoutOutlined
                  style={{ fontSize: 24, position: "relative", top: -2 }}
                  onClick={() => this.props.logout()}
                />
              </p>
            )}
            <h3
              style={{
                cursor: "pointer",
                position: "absolute",
                right: 10,
                fontSize: 16,
                zIndex: 5,
              }}
            >
              {this.props.history.location.pathname !== "/login" &&
                !this.props.user && (
                  <span
                    className="mx-2"
                    onClick={() => this.props.history.push("/login")}
                  >
                    {t("Login")}
                  </span>
                )}
              <span
                className="mx-2"
                onClick={() =>
                  this.props.switchLanguage(
                    config.language === "arabic" ? "english" : "arabic"
                  )
                }
              >
                {config.language === "arabic" ? "English" : "عربي"}
              </span>
            </h3>
            <h1
              style={{
                color: "#0099cc",
                fontWeight: 700,
                marginBottom: 50,
                fontSize: 75,
              }}
              className="ordable-header"
            >
              {config.language === "arabic" ? (
                <>
                  <span>/</span>
                  <span>اوردبل</span>
                </>
              ) : (
                "ordable/"
              )}
            </h1>
            <h4
              style={{
                position: "relative",
                top: -45,
                fontWeight: "bold",
                color: "#474444",
                fontSize: 16,
              }}
              className="simplified"
            >
              {config.language === "arabic"
                ? "الحل الامثل للمتاجر الالكترونية"
                : "online selling, simplified"}
            </h4>
            {this.shouldRenderStepper() && (
              <div className="text-center mb-5">
                <Steps
                  className="progressStepper"
                  size="small"
                  current={this.findCurrentStep()}
                  style={{ maxWidth: 600, margin: "auto" }}
                >
                  <Step
                    title={t("Package")}
                    //description={this.renderPackageDescription()}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.props.history.replace("/package");
                    }}
                  />
                  <Step title={t("Sign up")} />
                  <Step title={t("Verify")} />
                  <Step title={t("Store info")} />
                </Steps>
              </div>
            )}
            {loading ? loadingSpinner : <Switch>{this.renderRoutes()}</Switch>}
          </div>
        </div>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    loading: state.auth.loading,
    selectedPackage: state.auth.selectedPackage,
    stayOnVerificationPage: state.auth.stayOnVerificationPage,
    country: state.auth.user?.country || state.auth.country,
    config: state.config,
    finishedSignUp: state.auth.finishedSignUp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    switchLanguage: (lang) => dispatch(switchLanguage(lang)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(App)));
