import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Divider, Button, message, Select, Modal } from "antd";

// import { ReactComponent as Background } from "./Popup.svg";

import { withTranslation } from "react-i18next";

class GoDetails extends Component {
  render() {
    const { config, t } = this.props;
    const perks = [
      "Website name (yourbrand.ordable.com)",
      "1 User and branch",
      "Manage your catalog with up to 100 products",

      "Delivery service integration (preferred) ",
      "Accept payments online (Hesabe)",
      "Choose from 7 different designs",
      "Sales and performance reports",
      "Pick-up, delivery or offline orders",
      "Discount codes",
      "Google analytics",
      "Transfer your menu to or from Talabat or Deliveroo",
    ];

    const perks_ar = [
      "اسم موقع على اوردبل (yourbrand.ordable.com)",
      "ربط المتجر مع مقدمي خدمات التوصيل",
      "استقبال الدفع الالكتروني",
      "تقارير المبيعات والأداء",
      "إدارة دليل المنتجات بحجم ١٠٠ منتج",
      "استقبال طلبات التوصيل ، استلام شخصي ، و طلبات اوفلاين",
      "إمكانية تفعيل تخفيضات و حملات الترويج",
      "اختيار تصميم المتجر من بين ٧ تصاميم",
      "أدوات تسويق على Facebook, Instagram و Google",
      "نقل القائمة من او الى طلبات او ديليفرو",
    ];
    return (
      <div
        style={{
          padding: 50,
          direction: config.direction,
          textAlign: config.direction === "ltr" ? "left" : "right",
        }}
      >
        <Row style={{ height: "100%" }}>
          <Col
            xxl={config.direction === "ltr" ? 6 : 8}
            xl={config.direction === "ltr" ? 6 : 8}
            lg={config.direction === "ltr" ? 6 : 8}
            md={8}
            sm={24}
            xs={24}
          >
            <h1 style={{ fontWeight: 900, fontSize: 50, color: "#0099cc" }}>
              go
            </h1>
            <p style={{ fontWeight: 800, maxWidth: 185, color: "#0099cc" }}>
              {t("pay as you grow")}
            </p>
          </Col>

          <Col
            xxl={config.direction === "ltr" ? 18 : 16}
            xl={config.direction === "ltr" ? 18 : 16}
            lg={config.direction === "ltr" ? 18 : 16}
            md={16}
            sm={24}
            xs={24}
          >
            <div style={{ paddingTop: 30, paddingBottom: 50 }}>
              {(config.language === "arabic" ? perks_ar : perks).map((pe) => (
                <p
                  style={{
                    marginBottom: 6,
                    color: "#0099cc",
                    marginBottom: 12,
                  }}
                >
                  • {pe}
                </p>
              ))}
            </div>
          </Col>
        </Row>

        <span
          style={{
            height: 50,
            width: 110,
            borderRadius: 50,
            border: "1px solid #0099cc",
            position: "absolute",
            bottom: 20,
            [`${config.direction === "ltr" ? "right" : "left"}`]: 20,
            cursor: "pointer",
            display: "grid",
            justifyContent: "center",
            alignContent: "center",
            fontSize: 16,
            color: "#0099cc",
            background: "white",
          }}
          onClick={() => this.props.onSelect(1)}
        >
          {config.language === "arabic" ? "اختر" : "sign up"}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registrationData: state.registrationData,
    user: state.auth.user,
    selectedPackage: state.auth.selectedPackage,
    country: state.auth.user?.country || state.auth.country || "Kuwait",
    config: state.config,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(GoDetails));
