import jwt_decode from "jwt-decode";
import {
  SET_CURRENT_USER,
  SET_ERROR,
  AUTH_LOADING,
  VERIFY_ACCOUNT,
  STORE_CREATION_SUCCESS,
  SET_PACKAGE,
  UPDATE_USER,
} from "./actionTypes";
import instance from "./instance";
import axios from "axios";
import { message } from "antd";

export const login = (userData) => {
  return async (dispatch) => {
    try {
      const res = await instance.post("/go/login/", userData);

      const user = res.data;
      dispatch(setCurrentUser(user.access));
    } catch (err) {
      const response = err.response;
      message.error("Something went wrong. Please try again");
      if (response) dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };
};

const setCurrentUser = (token) => {
  return async (dispatch) => {
    dispatch({ type: AUTH_LOADING, payload: true });
    let user;

    if (token) {
      localStorage.setItem("authToken", token);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      instance.defaults.headers.common.Authorization = `Bearer ${token}`;

      user = jwt_decode(token);

      try {
        const res = await instance.get("/go/get_user_info/");
        if (res.data.success) {
          if (res.data.url) {
            window.location.href = res.data.url;
          } else
            dispatch({
              type: SET_CURRENT_USER,
              payload: { ...user, ...res.data.user },
            });
        }
      } catch (e) {
        console.error(e);
        message.error("Couldn't get account info");
        localStorage.removeItem("authToken");
        delete instance.defaults.headers.common.Authorization;
        delete axios.defaults.headers.common.Authorization;

        user = null;
        dispatch({
          type: SET_CURRENT_USER,
          payload: user,
        });
      }
    } else {
      localStorage.removeItem("authToken");
      delete instance.defaults.headers.common.Authorization;
      delete axios.defaults.headers.common.Authorization;

      user = null;
      dispatch({
        type: SET_CURRENT_USER,
        payload: user,
      });
    }
    dispatch({ type: AUTH_LOADING, payload: false });
  };
};

export const logout = () => setCurrentUser();

export const checkForExpiredToken = () => {
  // Check for token expiration
  const token = localStorage.getItem("authToken");
  let user = null;
  if (token) {
    const currentTimeInSeconds = Date.now() / 1000;

    // Decode token and get user info
    try {
      user = jwt_decode(token);
    } catch (e) {
      console.log("Invalid token");
      return logout();
    }

    // Check token expiration
    if (user.exp >= currentTimeInSeconds) {
      // Set user
      return setCurrentUser(token);
    }
  }
  return logout();
};

export const verifyAccount = () => {
  return {
    type: VERIFY_ACCOUNT,
  };
};
export const storeCreationSuccess = () => {
  return {
    type: STORE_CREATION_SUCCESS,
  };
};

export const setPackage = (p) => {
  return {
    type: SET_PACKAGE,
    payload: p,
  };
};

export const updateUser = (user) => {
  return { type: UPDATE_USER, payload: user };
};
