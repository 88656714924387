import React, { Component } from "react";
import { connect } from "react-redux";
import { setPackage, updateUser } from "../../store/actions/auth";
import { Col, Row, Divider, Button, message, Select, Modal, Radio } from "antd";
import instance from "../../store/actions/instance";
import { ReactComponent as KuwaitFlag } from "../assets/flags/kuwait.svg";
import { ReactComponent as QatarFlag } from "../assets/flags/qatar.svg";
import { ReactComponent as UAEFlag } from "../assets/flags/uae.svg";
import { ReactComponent as OmanFlag } from "../assets/flags/oman.svg";
import { ReactComponent as BahrainFlag } from "../assets/flags/bahrain.svg";
import { ReactComponent as SaudiFlag } from "../assets/flags/sa.svg";
import Packages from "../Packages/Packages";
import Background from "./Popup.svg";
import { DownOutlined } from "@ant-design/icons";
// import { ReactComponent as Background } from "./Popup.svg";

import { withTranslation } from "react-i18next";
import BasicDetails from "./BasicDetails";
import AdvancedDetails from "./AdvancedDetails";
import EnterpriseDetails from "./EnterpriseDetails";
import GoDetails from "./GoDetails";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
const { Option } = Select;

class Package extends Component {
  componentDidMount = () => {
    ReactPixel.trackCustom("PackagesPage");
    ReactGA.event({
      category: "Signup flow",
      action: "Customer viewed the packages page",
    });
  };
  state = {
    selectedPackage:
      this.props.selectedPackage === null ? 0 : this.props.selectedPackage,
    continueButtonLoading: false,
  };

  continueButtonDisabled = () => {
    return this.state.selectedPackage === null;
  };

  updatePackage = async (id) => {
    this.setState({ continueButtonLoading: true });
    try {
      const res = await instance.post("/go/update_package/", {
        selectedPackage: id,
        country: this.props.country,
        term: this.props.term,
      });
      if (res.data.success) {
        this.props.updateUser(res.data.user);
        this.props.history.push("/signup");
      } else {
        message.error(res.data.message || "Something went wrong");
      }
    } catch (e) {
      console.error(e);
      message.error("Something went wrong");
    }
    this.setState({ continueButtonLoading: false });
  };

  renderPackageDetail = () => {
    const { modalOpen } = this.state;
    const { user } = this.props;
    const onSelect = (packageID) => {
      this.setState({ modalOpen: false });
      if (!user) {
        this.props.setPackage(packageID);
        this.props.history.push("/signup");
      } else {
        this.updatePackage(packageID);
      }
    };
    switch (modalOpen) {
      case "go":
        return <GoDetails onSelect={onSelect} />;
      case "basic":
        return <BasicDetails onSelect={onSelect} />;
      case "advanced":
        return <AdvancedDetails onSelect={onSelect} />;
      case "enterprise":
        return <EnterpriseDetails onSelect={onSelect} />;
      default:
        return <></>;
    }
  };
  render() {
    const { selectedPackage } = this.state;
    const { user, t, config } = this.props;
    const flagStyle = { width: 20, height: 20, marginRight: 10 };
    const optionStyle = {
      textAlign: config.direction === "rtl" ? "right" : "left",
    };

    const shortDescriptions = {
      0: [
        "5% commission",
        "ordable.com domain",
        "1 user and 1 branch",
        "Up to 100 products",
        "Accept online payments",
        "Use partner delivery services",
        "Live Chat Support",
      ],
      1: [
        "0% commission",
        "Branded domain (www.yourbrand.com)",
        "6 users and 2 branches",
        "Up to 500 products",
        "Choose from many payment providers",
        "Choose from many delivery providers",
        "Sales & Performance Reporting",
        "Live Chat Support + Account Management",
      ],
      2: [
        "0% commission",
        "Branded domain (www.yourbrand.com)",
        "12 users and 5 branch",
        "Up to 2000 products",
        "Choose from many payment providers",
        "Choose from many delivery providers",
        "Advanced reports and heat maps",
        "Live Chat Support + Premium Account Management",
      ],
      3: [
        "0% commission",
        "Branded domain (www.yourbrand.com)",
        "20 users and 10 branch",
        "Up to 7500 products",
        "Choose from any payment providers",
        "Choose from many delivery providers",
        "Enterprise reporting by time, location and product",
        "Live Chat Support + Dedicated Account Management",
      ],
    };

    const shortDescriptionsAr = {
      0: [
        "5% عمولة",
        "اسم موقع على اوردبل (yourbrand.ordable.com)",
        "مستخدم واحد وفرع واحد",
        "لغاية 100 منتج",
        "الدفع اونلاين",
        "الربط مع شركة توصيل",
        "دعم فني اونلاين",
      ],
      1: [
        "0% عمولة",
        "اسم موقعكم الخاص (www.yourbrand.com)",
        "6 مستخدمين و فرعين",
        "لغاية 500 منتج",
        "العديد من بوابات الدفع",
        "العديد من شركات التوصيل",
        "تقارير المبيعات والأداء",
        "دعم فني اونلاين + رعاية حساب",
      ],
      2: [
        "0% عمولة",
        "اسم موقعكم الخاص (www.yourbrand.com)",
        "12 مستخدم و 5 أفرع",
        "لغاية 2000 منتج",
        "العديد من بوابات الدفع",
        "العديد من شركات التوصيل",
        "تقارير متقدمة وخرائط حرارية",
        "دعم فني اونلاين + رعاية حساب مميزة",
      ],
      3: [
        "0% عمولة",
        "اسم موقعكم الخاص (www.yourbrand.com)",
        "20 مستخدم و 10 أفرع",
        "لغاية 2000 منتج",
        "العديد من بوابات الدفع",
        "العديد من شركات التوصيل",
        "تقارير متقدمة حسب الوقت والموقع والمنتج",
        "دعم فني اونلاين + رعاية حساب خاصة",
      ],
    };

    return (
      <div style={{ position: "relative", top: -93 }}>
        <Modal
          title={<></>}
          footer={<></>}
          visible={this.state.modalOpen}
          onCancel={() => this.setState({ modalOpen: null })}
          closable={false}
          width={900}
          className="packageDetailModal"
          style={{ borderRadius: 10 }}
        >
          {this.renderPackageDetail()}
        </Modal>
        {/* <h4 className="mt-4" style={{ marginBottom: 30 }}>
          {t("Choose your package")}
        </h4> */}

        {/* <Select
            className="w-100"
            showSearch
            placeholder="Select a country"
            optionFilterProp="children"
            onChange={(v) =>
              this.props.changeCountry({ country: v, new: false })
            }
            filterOption={(input, option) => {
              return (
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            size={"large"}
            value={this.props.country}
            style={{
              maxWidth: 600,
              marginBottom: 10,
              textAlign: "left",
            }}
          >
            <Option value={"Kuwait"} style={optionStyle}>
              <KuwaitFlag style={flagStyle} /> {t("Kuwait")}
            </Option>
            <Option value={"Qatar"} style={optionStyle}>
              <QatarFlag style={flagStyle} /> {t("Qatar")}
            </Option>
            <Option value={"UAE"} style={optionStyle}>
              <UAEFlag style={flagStyle} /> {t("UAE")}
            </Option>
            <Option value={"Oman"} style={optionStyle}>
              <OmanFlag style={flagStyle} /> {t("Oman")}
            </Option>
            <Option value={"Bahrain"} style={optionStyle}>
              <BahrainFlag style={flagStyle} /> {t("Bahrain")}
            </Option>
            <Option value={"Saudi Arabia"} style={optionStyle}>
              <SaudiFlag style={flagStyle} /> {t("Saudi Arabia")}
            </Option>
          </Select> */}

        <div
          className="jumbotron mx-auto"
          style={{
            marginBottom: 17,
            padding: "17px 12px",
            maxWidth: 1360,
            background: "transparent",
          }}
        >
          <div style={{ height: 120 }} class="radio-group">
            <Radio.Group
              defaultValue="all"
              value={this.props.country}
              buttonStyle="solid"
              onChange={(v) =>
                this.props.changeCountry({
                  country: v.target.value,
                  new: false,
                })
              }
              className="custom-radio"
              style={{ float: "left" }}
            >
              <Radio.Button
                value="Kuwait"
                style={{
                  [`borderTop${
                    config.direction === "ltr" ? "Left" : "Right"
                  }Radius`]: 20,
                  [`borderBottom${
                    config.direction === "ltr" ? "Left" : "Right"
                  }Radius`]: 20,
                }}
              >
                {t("KWD")}
              </Radio.Button>
              <Radio.Button value="Saudi Arabia">{t("SAR")}</Radio.Button>
              <Radio.Button
                value="Bahrain"
                style={{
                  [`borderTop${
                    config.direction === "ltr" ? "Right" : "Left"
                  }Radius`]: 20,
                  [`borderBottom${
                    config.direction === "ltr" ? "Right" : "Left"
                  }Radius`]: 20,
                }}
              >
                {t("BHD")}
              </Radio.Button>
            </Radio.Group>

            <Radio.Group
              defaultValue="all"
              value={this.props.term}
              buttonStyle="solid"
              onChange={(v) =>
                this.props.changeTerm({
                  term: v.target.value,
                  new: false,
                })
              }
              style={{ float: "right" }}
              className="custom-radio"
            >
              <Radio.Button
                value="yearly"
                style={{
                  [`borderTop${
                    config.direction === "ltr" ? "Left" : "Right"
                  }Radius`]: 20,
                  [`borderBottom${
                    config.direction === "ltr" ? "Left" : "Right"
                  }Radius`]: 20,
                }}
              >
                / {t("year")}
              </Radio.Button>
              <Radio.Button
                value="monthly"
                style={{
                  [`borderTop${
                    config.direction === "ltr" ? "Right" : "Left"
                  }Radius`]: 20,
                  [`borderBottom${
                    config.direction === "ltr" ? "Right" : "Left"
                  }Radius`]: 20,
                }}
              >
                / {t("month")}
              </Radio.Button>
            </Radio.Group>
          </div>
          <Row gutter={[20, 40]} justify="center">
            {Object.keys(Packages[this.props.country]).map((pkg) => {
              pkg = parseInt(pkg);
              const price =
                Packages[this.props.country][pkg][`${this.props.term}Price`];
              const name = Packages[this.props.country][pkg].name;
              const name_ar = Packages[this.props.country][pkg].name_ar;
              const term = Packages[this.props.country][pkg].term;
              const isBest = Packages[this.props.country][pkg].best;
              const description = Packages[this.props.country][pkg].description;
              const packageID = Packages[this.props.country][pkg].id;
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={6}
                  style={{
                    position: "relative",
                    maxWidth: 310,
                    textAlign: config.direction === "ltr" ? "left" : "right",
                  }}
                >
                  {/* {packageID === 2 && (
                    <p
                      style={{
                        color: "#0d42fb",
                        position: "absolute",
                        top: -38,
                        fontSize: 20,
                        width: 170,
                        right: 0,
                        left: 114,
                        margin: "auto",
                      }}
                    >
                      most popular!
                    </p>
                  )} */}
                  {/* {packageID === 2 && (
                    <span
                      style={{
                        height: 10,
                        width: 70,
                        backgroundColor: "#0099cc",
                        transform: "rotate(147deg)",
                        position: "absolute",
                        left: -18,
                        top: 16,
                      }}
                    ></span>
                  )} */}
                  {packageID === 2 && (
                    <div
                      style={{
                        height: 25,
                        background: "#0099cc",
                        textAlign: "center",
                        color: "white",
                        fontSize: 13,
                        position: "relative",
                        top: -25,
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        letterSpacing: config.language === "arabic" ? 1 : 5,
                        display: "grid",
                        justifyContent: "center",
                        alignContent: "center",
                        width: "100%",
                      }}
                    >
                      {t("most popular")}
                    </div>
                  )}
                  <div
                    className={name.concat("Hover")}
                    style={{
                      //maxWidth: 340,
                      height: "100%",
                      background: "#F8F8F8",
                      borderRadius: 4,
                      padding: "30px 25px",
                      margin: "auto",
                      position: "relative",
                      top: packageID === 2 && -25,
                      borderTopLeftRadius: packageID === 2 ? 0 : 4,
                      borderTopRightRadius: packageID === 2 ? 0 : 4,
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 900,
                        marginBottom: 10,
                        color: "inherit",
                        textAlign:
                          config.direction === "ltr" ? "left" : "right",
                      }}
                    >
                      {config.language === "arabic" ? name_ar : name}
                    </h1>
                    <p
                      style={{
                        color: "inherit",
                        marginBottom: 5,
                        fontSize: 16,
                        textAlign:
                          config.direction === "ltr" ? "left" : "right",
                      }}
                    >
                      {" "}
                      {price
                        .replace(
                          "KD",
                          config.language === "arabic" ? "د.ك" : "KD"
                        )
                        .replace(
                          "SAR",
                          config.language === "arabic" ? "ر.س" : "SAR"
                        )
                        .replace(
                          "BD",
                          config.language === "arabic" ? "د.ب" : "BD"
                        )}{" "}
                    </p>
                    <p
                      style={{
                        color: "inherit",
                        marginBottom: 5,
                        fontSize: 15,
                        textAlign:
                          config.direction === "ltr" ? "left" : "right",
                        minHeight: 40,
                      }}
                    >
                      {t(description)}
                    </p>

                    <div
                      style={{
                        background: "white",
                        width: "100%",
                        height: 35,
                        borderRadius: 15,
                        marginTop: 0,
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      className="chooseButtonBorder"
                      onClick={() => {
                        if (packageID === 3) {
                          window.open(
                            config.language === "arabic"
                              ? "https://www.itsordable.com/contact-us-ar/"
                              : "https://www.itsordable.com/contact-us-en/",
                            "_blank"
                          );
                          return;
                        }
                        if (!user) {
                          this.props.setPackage(packageID);
                          this.props.history.push("/signup");
                        } else {
                          this.updatePackage(packageID);
                        }
                      }}
                    >
                      {/* <span style={{ color: "#0D42FB" }}>Choose</span> */}

                      <span style={{ color: "#474444" }}>
                        {packageID === 3 ? t("Contact us") : t("Choose")}
                      </span>
                    </div>

                    <div className="divider" style={{ marginTop: 28 }}></div>

                    <div style={{ padding: "0px 10px", marginTop: 20 }}>
                      <div
                        style={{
                          minHeight: config.direction === "ltr" ? 340 : 260,
                        }}
                        className="packageDetailsList"
                      >
                        {(config.language === "arabic"
                          ? shortDescriptionsAr
                          : shortDescriptions)[packageID].map((d) => (
                          <p
                            className={
                              config.direction === "ltr"
                                ? "text-left"
                                : "text-right"
                            }
                            style={{
                              color: "inherit",
                              fontSize: 12,
                              marginBottom: 8,
                            }}
                          >
                            {d}
                          </p>
                        ))}
                      </div>

                      <p
                        className={packageID !== 2 ? "findOutMore" : ""}
                        style={{
                          color: "inherit",
                          textDecoration: "underline",
                          cursor: "pointer",
                          textAlign: "center",
                          position: "absolute",
                          bottom: 40,
                          left: 0,
                          right: 0,
                          margin: "auto",
                          width: 125,
                        }}
                        onClick={() => this.setState({ modalOpen: name })}
                      >
                        {t("find out more")}
                      </p>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registrationData: state.registrationData,
    user: state.auth.user,
    selectedPackage: state.auth.selectedPackage,
    country: state.auth.user?.country || state.auth.country || "Kuwait",
    term: state.auth.user?.term || state.auth.term || "yearly",
    config: state.config,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPackage: (p) => dispatch(setPackage(p)),
    updateUser: (user) => dispatch(updateUser(user)),
    changeCountry: (country) =>
      dispatch({ type: "SET_COUNTRY", payload: country }),
    changeTerm: (term) => dispatch({ type: "SET_TERM", payload: term }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Package));
