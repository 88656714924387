import { combineReducers } from "redux";

import registrationData from "./registrationData";
import auth from "./auth";
import config from "./config";

const rootReducer = combineReducers({
  config: config,
  auth: auth,
  registrationData: registrationData,
});

export default rootReducer;
