const packages = {
  Kuwait: {
    0: {
      id: 0,
      name: "go",
      name_ar: "go",
      yearlyPrice: "0 KD",
      monthlyPrice: "0 KD",
      description: "pay as you grow",
    },
    1: {
      id: 1,
      name: "basic",
      name_ar: "الاساسية",
      yearlyPrice: "250 KD",
      monthlyPrice: "25 KD",
      description: "for start-ups",
    },
    2: {
      id: 2,
      name: "advanced",
      name_ar: "المتقدمة",
      yearlyPrice: "450 KD",
      monthlyPrice: "45 KD",
      description: "for growing businesses",
    },
    3: {
      id: 3,
      name: "enterprise",
      name_ar: "الفائقة",
      yearlyPrice: "750 KD",
      monthlyPrice: "75 KD",
      description: "big features for big businesses",
    },
  },
  Bahrain: {
    // 0: {
    //   id: 0,
    //   name: "go",
    //   name_ar: "go",
    //   yearlyPrice: "0 BD",
    //   monthlyPrice: "0 BD",
    //   description: "pay as you grow",
    // },
    // 1: {
    //   id: 1,
    //   name: "basic",
    //   name_ar: "الاساسية",
    //   yearlyPrice: "129 BD",
    //   monthlyPrice: "15 BD",
    //   description: "for start-ups",
    // },
    2: {
      id: 2,
      name: "advanced",
      name_ar: "المتقدمة",
      yearlyPrice: "249 BD",
      monthlyPrice: "25 BD",
      description: "for growing businesses",
    },
    3: {
      id: 3,
      name: "enterprise",
      name_ar: "الفائقة",
      yearlyPrice: "499 BD",
      monthlyPrice: "50 BD",
      description: "big features for big businesses",
    },
  },
  "Saudi Arabia": {
    0: {
      id: 0,
      name: "go",
      name_ar: "go",
      yearlyPrice: "0 SAR",
      monthlyPrice: "0 SAR",
      description: "pay as you grow",
    },
    1: {
      id: 1,
      name: "basic",
      name_ar: "الاساسية",
      yearlyPrice: "3500 SAR",
      monthlyPrice: "400 SAR",
      description: "for start-ups",
    },
    2: {
      id: 2,
      name: "advanced",
      name_ar: "المتقدمة",
      yearlyPrice: "6000 SAR",
      monthlyPrice: "700 SAR",
      description: "for growing businesses",
    },
    3: {
      id: 3,
      name: "enterprise",
      name_ar: "الفائقة",
      yearlyPrice: "9000 SAR",
      monthlyPrice: "900 SAR",
      description: "big features for big businesses",
    },
  },
};

export default packages;
