import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Divider, Button, message, Select, Modal } from "antd";

// import { ReactComponent as Background } from "./Popup.svg";

import { withTranslation } from "react-i18next";

class BasicDetails extends Component {
  render() {
    const { config, t } = this.props;

    const perks = [
      "Own website name (www.yourbrand.com)",
      "Delivery service integration",
      "Online payment gateways / direct banking",
      "Enterprise reporting by time, location and product",
      "Multi level categories with up to 7500 products",
      "POS+ and/or Cloud POS integrations",
      "Pick-up, Delivery or Offline Orders",
      "Discount codes, Promotions and Smart Promotions",
      "Choose from 7 different designs",
      "White-label: no ordable/ branding",
      "Advanced tools for Facebook, Instagram, Google, TikTok",
      "Transfer your menu to or from Talabat or Deliveroo",
      "Up to 10 branches and 20 users",
      "Invoicing and send link for payment",
      "Advanced Stock management and Inventory features",
      "Advance options and modifiers",
      "Dedicated account management and support",
    ];

    const perks_ar = [
      "اسم موقعكم الخاص (www.yourbrand.com)",
      "ربط المتجر مع مقدمي خدمات التوصيل",
      "استقبال الدفع الالكتروني",
      "تقارير متقدمة و متخصصة مع إمكانية الفرز عن بالوقت و الموقع و المنتج",
      "أنماط متعددة المستويات بحجم ٧٥٠٠ منتج",
      "نظام نقاط البيع مع نمط الكلاود",
      "استقبال طلبات التوصيل ، استلام شخصي ، و طلبات اوفلاين",
      "إمكانية تفعيل تخفيضات و حملات الترويج عامة و متقدمة",
      "اختيار تصميم المتجر من بين ٧ تصاميم",
      "أدوات تسويق متقدمة على Facebook, Instagram, Google, TikTok ",
      "نقل القائمة من او الى طلبات او ديليفرو",
      "إزالة بصمة اوردبل",
      "إمكانية ربط ١٠ افرع و ٢٠ مستخدم",
      "روابط للفواتير و الدفع",
      "ميزات إدارة المخزون",
      "خيارات إضافية متقدمة مع أنماط متعددة",
      "إدارة حساب متخصصة مع الدعم من خدمة العملاء",
    ];
    return (
      <div
        style={{
          padding: 50,
          direction: config.direction,
          textAlign: config.direction === "ltr" ? "left" : "right",
        }}
      >
        <Row style={{ height: "100%" }} gutter={[25, 0]}>
          <Col xxl={8} xl={8} lg={8} md={9} sm={24} xs={24}>
            <h1
              style={{
                fontWeight: 900,
                fontSize: 40,
                color: "#0099cc",
                whiteSpace: "nowrap",
              }}
            >
              {config.language === "arabic" ? "الفائقة" : "enterprise"}
            </h1>
            <p style={{ fontWeight: 800, maxWidth: 185, color: "#0099cc" }}>
              {t("big features for big businesses")}
            </p>
          </Col>
          <Col xxl={16} xl={16} lg={16} md={15} sm={24} xs={24}>
            <div style={{ paddingTop: 30, paddingBottom: 50 }}>
              {(config.language === "arabic" ? perks_ar : perks).map((pe) => (
                <p
                  style={{
                    marginBottom: 6,
                    color: "#0099cc",
                    marginBottom: 12,
                  }}
                >
                  • {pe}
                </p>
              ))}
            </div>
          </Col>
        </Row>

        <span
          style={{
            height: 50,
            width: 110,
            borderRadius: 50,
            border: "1px solid #0099cc",
            position: "absolute",
            bottom: 20,
            [`${config.direction === "ltr" ? "right" : "left"}`]: 20,
            cursor: "pointer",
            display: "grid",
            justifyContent: "center",
            alignContent: "center",
            fontSize: 16,
            color: "#0099cc",
            background: "white",
          }}
          onClick={() =>
            window.open(
              config.language === "arabic"
                ? "https://www.itsordable.com/contact-us-ar/"
                : "https://www.itsordable.com/contact-us-en/",
              "_blank"
            )
          }
        >
          {config.language === "arabic" ? "تواصل معنا" : "contact us"}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registrationData: state.registrationData,
    user: state.auth.user,
    selectedPackage: state.auth.selectedPackage,
    country: state.auth.user?.country || state.auth.country || "Kuwait",
    config: state.config,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(BasicDetails));
