import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/english/translation.js";
import translationAR from "./locales/arabic/translation.js";

// the translations
const resources = {
  english: {
    translation: translationEN,
  },
  arabic: {
    translation: translationAR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "english",

    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: "|",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
