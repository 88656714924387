import React, { Component } from "react";
import { Input, Button, Space, Spin, message, Form } from "antd";
import { connect } from "react-redux";
import { login } from "../../store/actions/auth";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import instance from "../../store/actions/instance";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const validateSubdomain = (val) =>
  instance.get(`/check_user_availability/${val}/`);
const searchDebounced = AwesomeDebouncePromise(validateSubdomain, 1000);

class UserInfo extends Component {
  componentDidMount = () => {
    ReactPixel.trackCustom("SignUpPage");
    ReactGA.event({
      category: "Signup flow",
      action: "Customer viewed the signup page",
    });
  };
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",

    emailLoading: false,
    emailValid: false,
    emailErrorReason: null,
    passwordValid: false,

    continueButtonLoading: false,

    mode: 0, // 0: Sign up, 1: Sign in
  };
  componentDidMount = () => {
    if (
      this.props.history.location.pathname === "/signup" &&
      (this.props.selectedPackage === null ||
        this.props.selectedPackage === undefined)
    ) {
      this.props.history.replace("/package");
    }
    if (this.props.history.location.pathname === "/login") {
      this.setState({ mode: 1 });
    }
  };
  continueButtonDisabled = () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      emailLoading,
      emailValid,
      passwordValid,
      mode,
    } = this.state;
    if (mode === 1) {
      if (this.validateEmail(email) && passwordValid) return false;
      return true;
    }
    if (emailLoading) return true;
    if (
      firstName?.trim()?.length > 2 &&
      lastName?.trim()?.length > 2 &&
      this.validateEmail(email) &&
      phone &&
      isValidPhoneNumber(phone) &&
      emailValid &&
      passwordValid
    )
      return false;
    return true;
  };
  validateEmail = (mail) => {
    if (!mail?.trim()?.length) return false;
    if (
      /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  checkEmail = async (query) => {
    const { t } = this.props;
    if (!query) {
      return;
    }
    this.setState({ emailLoading: true });
    try {
      const res = await searchDebounced(query);

      if (
        this.state.email?.trim()?.length &&
        /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/.test(
          this.state.email
        )
      )
        this.setState({
          emailValid: !!res.data,
          emailErrorReason: (!res.data && t("That email is taken")) || null,
        });
      else
        this.setState({
          emailValid: false,
          emailErrorReason: t("Email is invalid"),
        });
    } catch (e) {
      console.error(e);
    }
    this.setState({ emailLoading: false });
  };

  onChangeEmail = (e) => {
    const { t } = this.props;
    this.setState({ email: e.target.value });
    if (e.target.value?.trim()?.length) {
      if (
        /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/.test(
          e.target.value
        )
      )
        this.checkEmail(e.target.value.trim());
      else
        this.setState({
          emailValid: false,
          emailErrorReason: t("Email is invalid"),
        });
    } else
      this.setState({
        emailValid: false,
        emailErrorReason: t("Email is invalid"),
      });
  };

  onChangePassword = (e) => {
    this.setState({ password: e.target.value });
    if (e.target.value?.trim()?.length >= 8)
      this.setState({ passwordValid: true });
    else this.setState({ passwordValid: false });
  };
  renderEmailSuffix = () => {
    const { emailValid, emailLoading, mode } = this.state;
    if (mode === 1) return <></>;
    if (emailLoading) return <Spin indicator={antIcon} />;
    if (this.state.email) {
      if (emailValid) return <CheckOutlined style={{ color: "green" }} />;
      else {
        if (
          /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/.test(
            this.state.email
          )
        )
          return <CloseOutlined style={{ color: "red" }} />;
        else return <></>;
      }
    }
    return <></>;
  };

  createGoUser = async () => {
    this.setState({ continueButtonLoading: true });
    try {
      const res = await instance.post("/create_go_user/", {
        ...this.state,
        selectedPackage: this.props.selectedPackage,
        login: this.state.mode === 1,
        country: this.props.country || "Kuwait",
        term: this.props.term || "yearly",
      });
      if (res.data.success) {
        if (res.data.url) {
          window.location.href = res.data.url;
        } else {
          await this.props.login(this.state);
          this.props.history.push("/verify");
        }
      } else {
        message.error(res.data.message || "Something went wrong");
      }
    } catch (e) {
      message.error("Something went wrong");
    }
    this.setState({ continueButtonLoading: false });
  };

  clearState = () => {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",

      emailLoading: false,
      emailValid: false,
      passwordValid: false,
      phoneError: false,

      continueButtonLoading: false,
    });
  };
  render() {
    const { user, config, t } = this.props;
    if (user) {
      return <Redirect to="/verify" />;
    }

    const inputStyle = {
      paddingTop: 13,
      paddingBottom: 13,
    };

    return (
      <div className="my-4">
        <h4 className="mt-4" style={{ marginBottom: 30 }}>
          {this.state.mode === 0
            ? t("Create your account")
            : t("Sign in to your account")}
        </h4>

        <Form
          className="mx-auto"
          style={{ maxWidth: 331, width: "calc(100% - 66px)" }}
        >
          {/* <Space direction="vertical" style={{ width: "100%" }} size="middle"> */}
          {this.state.mode === 0 && (
            <>
              <Form.Item
                name="FirstName"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: t("Required") },

                  {
                    min: 3,
                    message: t("First name should be 3 characters or longer"),
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={t("First Name")}
                  style={inputStyle}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                  value={this.state.firstName}
                />
              </Form.Item>

              <Form.Item
                name="LastName"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: t("Required") },
                  {
                    min: 3,
                    message: t("Last name should be 3 characters or longer"),
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={t("Last Name")}
                  style={inputStyle}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                  value={this.state.lastName}
                />
              </Form.Item>
              <Form.Item
                validateTrigger="onBlur"
                name="PhoneNumber"
                rules={[
                  {
                    required: true,
                    message: t("Required"),
                  },

                  {
                    validator: (_, value) => {
                      if (value && !isValidPhoneNumber(value))
                        return Promise.reject(
                          new Error(t("Phone number is invalid"))
                        );
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="KW"
                  onChange={(e) => this.setState({ phone: e })}
                  value={this.state.phone}
                  style={{ direction: "ltr" }}
                />
              </Form.Item>
            </>
          )}

          <Form.Item
            validateStatus={this.state.emailErrorReason && "error"}
            help={this.state.emailErrorReason}
            name="Email"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: t("Required"),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={t("Email Address")}
              style={{ ...inputStyle, direction: "ltr" }}
              onChange={this.onChangeEmail}
              suffix={this.renderEmailSuffix()}
              value={this.state.email}
            />
          </Form.Item>

          <Form.Item
            validateTrigger="onBlur"
            name="Password"
            rules={[
              {
                required: true,
                message: t("Required"),
              },
              {
                min: 8,
                message: t("Password should be 8 characters or longer"),
              },
            ]}
          >
            <Input.Password
              size="large"
              placeholder={t("Password")}
              style={{ ...inputStyle, direction: "ltr" }}
              onChange={this.onChangePassword}
              value={this.state.password}
            />
          </Form.Item>

          {this.state.mode === 0 ? (
            <p>
              {t("Already have an account?")}{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: "#0099cc",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  this.setState({ mode: 1 });
                  this.clearState();
                }}
              >
                {t("Sign in")}
              </span>
            </p>
          ) : (
            <>
              {this.state.mode === 1 && (
                <p className="mb-0">
                  {t("Don't have an account?")}{" "}
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#0099cc",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      if (!this.props.selectedPackage) {
                        this.props.history.replace("/package");
                      } else {
                        this.setState({ mode: 0 });
                        this.clearState();
                      }
                    }}
                  >
                    {t("Sign up")}
                  </span>
                </p>
              )}
            </>
          )}
          {/* </Space> */}
          {/* 
          <p style={{ fontSize: 12, marginTop: 30, marginBottom: 0 }}>
            By creating an account, you agree to our{" "}
            <span style={{ textDecoration: "underline" }}>
              Terms of Service
            </span>{" "}
            and have read and understood the{" "}
            <span style={{ textDecoration: "underline" }}>Privacy Policy</span>
          </p> */}
          <Form.Item>
            <Button
              htmlType="submit"
              className="py-3 w-100"
              style={{
                backgroundColor: this.continueButtonDisabled()
                  ? "#ebebeb"
                  : "#0099cc",
                color: this.continueButtonDisabled() ? "#d6d6d6" : "white",
                height: 60,
                border: "none",
                marginTop: 30,
              }}
              // disabled={this.continueButtonDisabled()}
              loading={this.state.continueButtonLoading}
              onClick={() => {
                !this.continueButtonDisabled() && this.createGoUser();
              }}
            >
              {t("CONTINUE")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    selectedPackage: state.auth.selectedPackage,
    country: state.auth.country,
    term: state.auth.term,
    config: state.config,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserInfo));
