export const SAVE_STEP_INFO = "SAVE_STEP_INFO";
export const GO_BACK = "GO_BACK";
export const TOGGLE_CREATION_LOADING = "TOGGLE_CREATION_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_ERROR = "SET_ERROR";
export const AUTH_LOADING = "AUTH_LOADING";
export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const STORE_CREATION_SUCCESS = "STORE_CREATION_SUCCESS";
export const SET_PACKAGE = "SET_PACKAGE";
export const UPDATE_USER = "UPDATE_USER";
export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";
