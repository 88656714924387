import React, { Component } from "react";
import { Modal } from "antd";

export default class Terms extends Component {
  render() {
    return (
      <Modal
        title={<></>}
        footer={<></>}
        visible={true}
        onCancel={() => this.props.onClose()}
        width={600}
        className="packageDetailModal"
        style={{ borderRadius: 10, textAlign: "right" }}
      >
        <div className="bold underline center mb-3">
          حقوق و واجبات الطرف الأول
        </div>

        <div>
          <span className="bold">1. السرية والخصوصية وحماية البيانات:</span>{" "}
          يوافق الطرف الثاني على حماية المعلومات السرية الخاصة بالطرف الأول بما
          في ذلك على سبيل المثال لا الحصر الخدمات والواجهة والعقد والتسعير وعدم
          الإفصاح عنها أو كشفها لأي من منافسيه.
        </div>
        <div>
          <span className="bold">2. إنهاء الخدمة</span>: يحتفظ الطرف الأول بالحق
          في تعليق الخدمة أو إنهائها. ما لم تتطلب الظروف إنهاء فوريًا، يُقدم
          الطرف الأول إشعارًا مسبق بمدة 30 يومًا.
        </div>
        <div>
          <span className="bold">3. تقديم الخدمات</span>: الطرف الأول لن يتحمل
          أي مسؤولية تسبب أي ضرر بسبب أي خطأ، سهو او تأخير بتقديم خدماته.
        </div>
        <div>
          <span className="bold">4. صيانة الموقع</span>: صيانة الموقع دون تحمل
          مسؤولية أي إضرار قد تحدث نتيجة لتعرض الموقع للخلل بسبب ظروف خارجة عن
          إرادته كالقوة القاهرة أو تعطل خدمة الإنترنت أو القرصنة أو لأي سبب
          آخر.
        </div>
        <div>
          <span className="bold">5. حقوق الطبع والنشر والدعاية</span>: يحتفظ
          الطرف الأول بالحق في إدراج اسم شركة الطرف الثاني وعلامته التجارية
          والشعار الخاص به وأي من مواد التسويق للشركة ضمن أنشطته الترويجية
          الخاصة.
        </div>
        <div>
          <span className="bold">6. الإشعارات والمراسلات ومستويات الخدمة</span>:
          يلتزم الطرف الأول بالإجابة عن أي طلب إذا تم إرساله على البريد
          الإلكتروني info@ordable.com. و بناءً على نوع الطلب، يُجيب الطرف الأول
          بقدرته على تلبية الطلب. يعتبر الإخطار قد تم تقديمه وتسليمه بشكل صحيح
          وسليم وحسب الأصول عند وصول تأكيد الاستلام في حالة إرساله عبر البريد
          الإلكتروني.
        </div>

        <div className="bold underline center my-3">
          حقوق و واجبات الطرف الثاني
        </div>

        <div>
          <span className="bold">1. موضوع الاتفاقية</span>: هو تقديم خدمات
          المتجر الإكترونى للطرف الثانى ، من خلال فتح نافذة إلكترونية له لعرض
          منتجاته وخدماته على الموقع الإلكترونية، يقوم الطرف الثاني من خلال تلك
          النافذة بعرض منتجاته لعملائه .يحق للطرف الثاني استخدام او عدم استخدام
          الخدمات المتوفرة مجانا او التي تم الدفع لها من قبل الطرف الثاني.
        </div>
        <div>
          <span className="bold">2. السرية والخصوصية وحماية البيانات</span>:
          يلتزم الطرف الأول بحماية خصوصية الطرف الثاني وبيانات عملائه والحفاظ
          على سريتها.
        </div>

        <div>
          <span className="bold">3. إنهاء الخدمة</span>: يحتفظ الطرف الثاني
          بالحق في إنهاء هذه الاتفاقية في أي وقت بموجب إشعارًا مسبقًا بمدة 30
          يومًا مع الالتزام بإغلاق وتسوية أي التزامات تجاه الطرف الأول بالكامل
          دون أي إخلال.
        </div>
        <div>
          <span className="bold">4. تقديم الخدمة</span>: يستخدم الطرف الثاني
          خدمات الطرف الاول على مسئوليته الخاصة.
        </div>
        <div>
          <span className="bold">5. تقديم المعلومات</span>: على الطرف الثاني
          تزويد الطرف الأول بمعلومات كاملة وصحيحة وصادقة، ويتحمل الطرف الثاني
          جميع وأي مسؤولية أو التزام ناتج عن تقديم معلومات خاطئة.
        </div>

        <div>
          <span className="bold">6. التعامل مع أطراف اخرى</span>: يجوز للطرف
          الثاني أن يستخدم خدمة من خلال أطراف اخرى – على سبيل المثال خدمات مالية
          أو خدمات توصيل من أي طرف ثالث، يتحمل الطرف الثاني أي مسئولية عن أي أمر
          قد ينشأ عن تلك الخدمات.
        </div>
        <div>
          <span className="bold">7. حل النزاعات</span>: تخضع هذه الاتفاقية وتؤول
          وفقًا لقوانين دولة الكويت وتختص محاكمها بأي نزاع ينشأ بصددها، وفي حالة
          نشوب أي خلافات أو تعارض في تفسير بنود وشروط هذه الاتفاقية، تسود النسخة
          العربية.
        </div>

        <div>
          <span className="bold">8. إقرار</span>: يُقر الطرف الثاني أنه قد اطلاع
          على جميع بنود وشروط الاتفاقية وقرأها بتمعن، ويوافق على جميع ما ورد بها
          بموجب توقيعه على الاتفاقية.
        </div>
      </Modal>
    );
  }
}
