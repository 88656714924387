export const storeCategories = [
  "Baby & Children's Clothing",
  "Bakery",
  "Bookstores",
  "Car Accessories",
  "Coffee Beans, Accessories & Tea",
  "Cosmetics & Beauty",
  "Dermatological & Skincare",
  "Outdoor Activities & Supplies",
  "Electronics & Accessories",
  "Flowers & Plants",
  "Food & Beverage",
  "Footwear & Sneakers",
  "Fragrances, Perfumes, Oud",
  "Grocery & Butchery",
  "Gym & Sports Equipment",
  "Cleaning & Supplies",
  "Home Interiors & Design",
  "Jewelry & Accessories",
  "Optics & Eyewear",
  "Pets Supplies",
  "Pharmacy",
  "Retail & Multibrand",
  "Spa's & Salons",
  "Stationary",
  "Toys",
  "Other",
];
