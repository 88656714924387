let tr = {
  Package: "الاشتراك",
  "Sign up": "التسجيل",
  Verify: "التحقق",
  "Store info": "معلومات المتجر",
  Kuwait: "الكويت",
  Qatar: "قطر",
  Bahrain: "البحرين",
  UAE: "الامارات",
  Oman: "عمان",
  "Saudi Arabia": "السعودية",
  "Choose your package": "اختر نوع الاشتراك",
  CONTINUE: "المتابعة",
  "Create your account": "انشئ حسابك",
  "Sign in to your account": "تسجيل الدخول الى حسابك",
  "First Name": "الاسم الاول",
  "Last Name": "الاسم الأخير",
  "Email Address": "الايميل",
  Password: "كلمة المرور",
  "Already have an account?": "لديك حساب مسجل؟",
  "Sign in": "تسجيل الدخول",
  "Don't have an account?": "",
  "Sign up": "التسجيل",
  "Don't have an account?": "ليس لديك حساب؟",
  "Security verification": "تحقق الحماية",
  "Please complete the following verification": "الرجاء اكمال التالي",
  "Phone verification code": "رمز تحقق الهاتف",
  "E-mail verification code": "رمز تحقق الايميل",
  "Enter the 6 digit code sent to": "ادخل الرمز المكون من 6 ارقام المرسل الى",
  Resend: "اعادة ارسال",
  "Verify phone later": "التحقق من الهاتف لاحقا",
  "Verify email later": "التحقق من الايميل لاحقا",
  "is verified": "تم التحقق من",
  "Tell us about your store": "اخبرنا عن متجرك",
  Country: "الدولة",
  edit: "تغيير",
  "Select a category": "اختر الصنف",
  Subdomain: "رابط الموقع الفرعي",
  "Store name": "اسم المتجر",
  "Please hold while we build your website":
    "الرجاء الانتظار بينما نقوم ببناء متجرك",
  "This should take a few moments. You will be redirected to your dashboard once this is done":
    "سيتم تحويلك الى شاشة تحكم موقعك خلال ثواني",
  year: "سنة",
  month: "شهر",
  KWD: "الكويت",
  SAR: "السعودية",
  BHD: "البحرين",
  "most popular": "الأكثر شهرة",
  Choose: "اختر",
  "find out more": "اعرف المزيد",
  "By clicking on CONTINUE, you agree to the":
    "عند الضغط على متابعة، انت توافق على",
  "terms and conditions": "الشروط والأحكام",
  "the most advanced features for your business": "اكثر مميزات تقدما لمشروعك",
  "all you need to start your online store":
    "كل ما تحتاجه لابتداء متجرك الالكتروني",
  "grow your business with pro tools": "نمي مشروعك مع احدث الادوات",
  "for start-ups": "للمشاريع الناشئة",
  basic: "الاساسية",
  advanced: "المتقدمة",
  enterprise: "الفائقة",
  Bakery: "مخبز",
  Restaurant: "مطعم",
  Grocery: "بقالة/جمعية",
  Cafe: "قهوة",
  "Gift Shop": "هدايا",
  Florist: "ورود",
  Beverage: "مشروبات",
  Dessert: "حلويات",
  "Food Establishment": "مؤسسة غذائية",
  Catering: "خدمات تقديم الطعام",
  "Home Business": "عمل منزلي",
  Toys: "العاب",
  Gym: "نادي صحي",
  Events: "حجوزات",
  Automotive: "سيارات",
  "Pet Store": "حيوانات اليفة",
  Water: "مياه",
  Butchery: "لحوم",
  Fashion: "ازياء",
  "Health & Beauty": "الصحة والجمال",
  Jewellery: "مجوهرات",
  Perfume: "عطور",
  "Home & Furnishing": "اثاث منزلي",
  "What products will you sell?": "ما هي نوعية المنتجات التي تبيعها؟",
  "Physical products": "منتجات جاهزة",
  "Bookable events": "حجوزات",
  "Digital downloads": "منتجات رقمية",
  "(eg. tickets)": "(مثل: تذاكر)",
  "(eg. food)": "(مثل: اكل)",
  "(eg. videos)": "(مثل: ملفات)",
  "pay as you grow": "انطلق بدون التزام",
  "for growing businesses": "زد مبيعاتك اونلاين",
  "big features for big businesses": "جميع المميزات للمؤسسات",
  Explanation: "شرح",
  "Sell products or items that can be delivered or picked up by customers. This can include products like food, flowers, groceries and the like. If you need inventory management, you can add this from your dashboard later.":
    "لبيع منتجات جاهزة او محضرة للتوصيل او الاستلام. على سبيل المثال: طعام او ورد.",

  "Sell products or services that happen at a specific day and time. This can be used for ticketing, classes, and other calendared services.":
    "لبيع الخدمات التي تكون محدودة بوقت و تاريخ معين. على سبيل المثال، تذاكر او محاضرات.",

  "Sell items that can be downloaded after customers buy them. This can be used for online videos, instagram filters, PDF downloads etc.":
    "منتج قابل للتحميل عند الشراء. على سبيل المثال ملفات، فلتر انستاغرام او فيديو تعليمي",
  Login: "تسجيل الدخول",

  "Phone number is invalid": "رقم الهاتف غير صحيح",
  Required: "مطلوب",
  "Email is invalid": "البريد الإلكتروني غير صحيح",
  "That email is taken": "البريد الإلكتروني مستخدم من قبل",
  "Password should be 8 characters or longer":
    "كلمة السر يجب ان تكون 8 احرف او اكثر",
  "First name should be 3 characters or longer":
    "الإسم الاول يجب ان يكون ثلاث احرف او اكثر",
  "Last name should be 3 characters or longer":
    "الإسم الأخير يجب ان يكون ثلاث احرف او اكثر",
  "contact us": "تواصل معنا",
  "Contact us": "تواصل معنا",

  "Baby & Children's Clothing": "ملابس أطفال",
  Bookstores: "مكتبات",
  "Car Accessories": "اكسسوارات السيارات",
  "Coffee Beans, Accessories & Tea": "القهوة والشاي ومستلزماتهم",
  "Cosmetics & Beauty": "منتجات التجميل",
  "Dermatological & Skincare": "منتجات الجلدية والعناية بالبشرة",
  "Outdoor Activities & Supplies": "الأنشطة الخارجية واللوازم",
  "Electronics & Accessories": "الالكترونيات واكسسواراتها",
  "Flowers & Plants": "الزهور والنباتات",
  "Food & Beverage": "طعام وشراب",
  "Footwear & Sneakers": "الأحذية",
  "Fragrances, Perfumes, Oud": "العطور والعود",
  "Grocery & Butchery": "البقالة والملحمة",
  "Gym & Sports Equipment": "معدات رياضية",
  "Cleaning & Supplies": "معدات تنظيف",
  "Home Interiors & Design": "تصاميم وديكورات منزلية",
  "Jewelry & Accessories": "المجوهرات والاكسسوارات",
  "Optics & Eyewear": "البصريات والنظارات",
  "Pets Supplies": "مستلزمات الحيوانات الأليفة",
  Pharmacy: "صيدلية",
  "Retail & Multibrand": "بيع التجزأة والماركات المتعددة",
  "Spa's & Salons": "سبا وصالون",
  Stationary: "قرطاسية",
  Other: "آخر",
};
export default tr;
