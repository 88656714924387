import {
  SET_CURRENT_USER,
  SET_ERROR,
  AUTH_LOADING,
  VERIFY_ACCOUNT,
  STORE_CREATION_SUCCESS,
  SET_PACKAGE,
  UPDATE_USER,
} from "../actions/actionTypes";

const initialState = {
  user: null,
  errors: null,
  loading: false,
  selectedPackage: null,
  stayOnVerificationPage: false,
  country: null,
  term: "yearly",
  finishedSignUp: false,
};

export default (state = initialState, { type, payload }) => {
  let newUser = null;
  switch (type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        user: payload,
        selectedPackage: payload?.package === 0 ? 0 : payload?.package || null,
        country: payload?.country || state.country,
        term: payload?.term || state.term,
        errors: null,
      };

    case SET_ERROR:
      return { ...state, errors: payload.non_field_errors };

    case AUTH_LOADING:
      return { ...state, loading: payload };

    case VERIFY_ACCOUNT:
      newUser = state.user;
      newUser.is_verified = true;
      return { ...state, user: { ...newUser } };

    case STORE_CREATION_SUCCESS:
      newUser = state.user;
      newUser.did_create_store = true;
      return { ...state, user: { ...newUser } };

    case SET_PACKAGE:
      return {
        ...state,
        selectedPackage: payload,
        user: !!state.user ? { ...state.user, package: payload } : null,
      };
    case UPDATE_USER:
      newUser = state.user;
      let stayOnVerificationPage = false;
      if (
        payload.email_verified &&
        payload.phone_verified &&
        (!newUser.email_verified || !newUser.phone_verified)
      ) {
        stayOnVerificationPage = true;
      }
      return {
        ...state,
        user: payload,
        selectedPackage: payload.package,
        country: payload.country,
        term: payload.term || "yearly",
        stayOnVerificationPage: stayOnVerificationPage,
      };
    case "COMPLETE_VERIFICATION":
      newUser = state.user;
      newUser.is_verified = true;
      return { ...state, stayOnVerificationPage: false, user: { ...newUser } };
    case "SET_COUNTRY":
      if (!state.user?.country) return { ...state, country: payload.country };
      else if (state.user?.country && !payload.new)
        return {
          ...state,
          country: payload,
          user: { ...state.user, country: payload.country },
        };
    case "SET_TERM":
      if (!state.user?.term) return { ...state, term: payload.term };
      else if (state.user?.term && !payload.new)
        return {
          ...state,
          term: payload.term,
          user: { ...state.user, term: payload.term },
        };
    case "DID_FINISH_SIGN_UP":
      return { ...state, finishedSignUp: true };
    default:
      return state;
  }
};
