import React, { Component } from "react";
import {
  Select,
  Space,
  Button,
  Input,
  Spin,
  message,
  Checkbox,
  Popover,
} from "antd";
import {
  LeftOutlined,
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { goBack, saveStepInfo } from "../../store/actions/registrationData";
import { storeCreationSuccess } from "../../store/actions/auth";
import instance from "../../store/actions/instance";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { storeCategories } from "./StoreCategories";
import { ReactComponent as KuwaitFlag } from "../assets/flags/kuwait.svg";
import { ReactComponent as QatarFlag } from "../assets/flags/qatar.svg";
import { ReactComponent as UAEFlag } from "../assets/flags/uae.svg";
import { ReactComponent as OmanFlag } from "../assets/flags/oman.svg";
import { ReactComponent as BahrainFlag } from "../assets/flags/bahrain.svg";
import { ReactComponent as SaudiFlag } from "../assets/flags/sa.svg";
import { withTranslation } from "react-i18next";
import Building from "./Building";
import Terms from "./Terms";
import ArTerms from "./ArTerms";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const validateSubdomain = (val) =>
  instance.get(`/check_subdomain_availability/${val}/`);
const searchDebounced = AwesomeDebouncePromise(validateSubdomain, 500);

const { Option } = Select;

class StoreInfo extends Component {
  componentDidMount = () => {
    ReactPixel.trackCustom("StoreInfoPage");
    ReactGA.event({
      category: "Signup flow",
      action: "Customer viewed the store info page",
    });
  };
  state = {
    storeCategory: this.props.registrationData.storeCategory,
    subdomain: this.props.registrationData.subdomain,
    storeName: this.props.registrationData.storeName,

    domainValid: this.props.registrationData.domainValid,
    domainLoading: this.props.registrationData.domainLoading,

    enable_produced: false,
    enable_booking: false,
    enable_digital: false,

    continueButtonLoading: false,

    dashURL: null,
  };

  renderCountryFlag = () => {
    const { country, t, config } = this.props;
    const flagStyle = {
      width: 20,
      height: 20,
      [`margin${config.language === "arabic" ? "Left" : "Right"}`]: 10,
      position: "relative",
      top: -2,
    };
    switch (country) {
      case "Kuwait":
        return <KuwaitFlag style={flagStyle} />;
      case "Bahrain":
        return <BahrainFlag style={flagStyle} />;
      case "Qatar":
        return <QatarFlag style={flagStyle} />;
      case "Saudi Arabia":
        return <SaudiFlag style={flagStyle} />;
      case "Kuwait":
        return <KuwaitFlag style={flagStyle} />;
      case "UAE":
        return <UAEFlag style={flagStyle} />;
      case "Oman":
        return <OmanFlag style={flagStyle} />;
      default:
        return <></>;
    }
  };
  continueButtonDisabled = () => {
    const {
      storeCategory,
      subdomain,
      storeName,
      domainValid,
      domainLoading,
      enable_produced,
      enable_booking,
      enable_digital,
    } = this.state;
    const { user } = this.props;

    if (user.package === 1) {
      if (
        [enable_produced, enable_booking, enable_digital].filter((x) => !!x)
          .length !== 1
      )
        return true;
    }
    if (user.package === 2) {
      if (
        [enable_produced, enable_booking, enable_digital].filter((x) => !!x)
          .length !== 2
      )
        return true;
    }
    if (
      storeCategory?.trim()?.length &&
      subdomain?.trim()?.length &&
      storeName?.trim()?.length &&
      !domainLoading &&
      domainValid
    )
      return false;

    return true;
  };

  checkDomain = async (query) => {
    if (!query) {
      return;
    }
    this.setState({ domainLoading: true });
    try {
      const res = await searchDebounced(query);

      if (
        this.state.subdomain.length &&
        /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(
          `${this.state.subdomain}.ordable.com`
        ) &&
        !this.state.subdomain.includes(".")
      )
        this.setState({ domainValid: !!res.data });
      else this.setState({ domainValid: false });
    } catch (e) {
      console.error(e);
    }
    this.setState({ domainLoading: false });
  };

  onChangeSubdomain = (e) => {
    this.setState({ subdomain: e.target.value });
    if (e.target.value?.trim()?.length) {
      if (
        /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(
          `${e.target.value}.ordable.com`
        ) &&
        !e.target.value.includes(".")
      )
        this.checkDomain(e.target.value.trim());
      else this.setState({ domainValid: false });
    } else this.setState({ domainValid: false });
  };

  renderSubdomainSuffix = () => {
    const { domainValid, domainLoading } = this.state;
    if (domainLoading) return <Spin indicator={antIcon} />;
    if (this.state.subdomain) {
      if (domainValid) return <CheckOutlined style={{ color: "green" }} />;
      else return <CloseOutlined style={{ color: "red" }} />;
    }
    return <></>;
  };

  submit = () => {
    this.props.storeCreationSuccess();
  };

  createStore = async () => {
    const { config } = this.props;

    this.setState({ continueButtonLoading: true });
    try {
      const res = await instance.post("/go/create_go_store/", {
        ...this.state,
        language: config.language,
      });

      if (res.data.success && res.data.dashURL) {
        this.setState({
          dashURL: res.data.dashURL.concat(
            config.language === "arabic" ? "&lang=ar" : ""
          ),
        });
        // window.location.href = res.data.dashURL;
      } else message.error(res.data.message || "Something went wrong");
    } catch (e) {
      console.error(e);
      message.error("Something went wrong");
    }
    this.setState({ continueButtonLoading: false });
  };
  renderPackageName = () => {
    const { t, user } = this.props;

    switch (user?.package) {
      case 0:
        return t("go");
      case 1:
        return t("basic");
      case 2:
        return t("advanced");
      case 3:
        return t("enterprise");
    }
  };
  render() {
    const { registrationData, country, config, t, user } = this.props;
    if (!user) return <Building dashURL={this.state.dashURL} />;
    const { termsOpen } = this.state;
    const inputStyle = {
      paddingTop: 13,
      paddingBottom: 13,
    };
    if (this.state.dashURL) return <Building dashURL={this.state.dashURL} />;
    const explanations = {
      produced: t(
        "Sell products or items that can be delivered or picked up by customers. This can include products like food, flowers, groceries and the like. If you need inventory management, you can add this from your dashboard later."
      ),
      booking: t(
        "Sell products or services that happen at a specific day and time. This can be used for ticketing, classes, and other calendared services."
      ),
      digital: t(
        "Sell items that can be downloaded after customers buy them. This can be used for online videos, instagram filters, PDF downloads etc."
      ),
    };
    return (
      <div className="my-4">
        {termsOpen &&
          (config.language === "arabic" ? (
            <ArTerms onClose={() => this.setState({ termsOpen: false })} />
          ) : (
            <Terms onClose={() => this.setState({ termsOpen: false })} />
          ))}
        <h4 className="mt-4" style={{ marginBottom: 30 }}>
          {t("Tell us about your store")}
        </h4>

        <div
          className="mx-auto"
          style={{ maxWidth: 331, width: "calc(100% - 66px)" }}
        >
          <Space direction="vertical" style={{ width: "100%" }} size="middle">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "40% auto",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #d9d9d9",
                padding: "0px 11px",
                height: 51,
              }}
            >
              <span
                className={
                  config.language === "arabic" ? "text-right" : "text-left"
                }
              >
                {t("Package")}
              </span>
              <span
                style={{ fontWeight: "bold", cursor: "pointer" }}
                onClick={() => this.props.history.replace("/package")}
              >
                <span style={{ display: "inline-block" }}>
                  {this.renderPackageName()}
                </span>
                <span
                  style={{
                    fontSize: 12,
                    color: "#0099cc",
                    [`margin${
                      config.language === "arabic" ? "Right" : "Left"
                    }`]: 10,
                  }}
                >
                  {t("edit")}
                </span>
              </span>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "40% auto",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #d9d9d9",
                padding: "0px 11px",
                height: 51,
              }}
            >
              <span
                className={
                  config.language === "arabic" ? "text-right" : "text-left"
                }
              >
                {t("Country")}
              </span>
              <span
                style={{ fontWeight: "bold", cursor: "pointer" }}
                onClick={() => this.props.history.replace("/package")}
              >
                <span>{this.renderCountryFlag()}</span>
                <span style={{ display: "inline-block" }}>{t(country)}</span>
                <span
                  style={{
                    fontSize: 12,
                    color: "#0099cc",
                    [`margin${
                      config.language === "arabic" ? "Right" : "Left"
                    }`]: 10,
                  }}
                >
                  {t("edit")}
                </span>
              </span>
            </div>

            <div>
              {/* <p className="mb-2 text-left">Store category</p> */}
              <Select
                className="w-100"
                showSearch
                placeholder={t("Select a category")}
                optionFilterProp="children"
                onChange={(v) => this.setState({ storeCategory: v })}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                size={"large"}
                defaultValue={registrationData.storeCategory || undefined}
              >
                {storeCategories.map((cat) => (
                  <Option
                    value={cat}
                    style={{
                      textAlign: config.direction === "ltr" ? "left" : "right",
                    }}
                  >
                    {t(cat)}
                  </Option>
                ))}
              </Select>
            </div>

            <div style={{ whiteSpace: "nowrap", direction: "ltr" }}>
              <Input
                placeholder={t("Subdomain")}
                style={{ width: "72%", ...inputStyle, float: "left" }}
                size={"large"}
                onChange={this.onChangeSubdomain}
                defaultValue={registrationData.subdomain}
                suffix={this.renderSubdomainSuffix()}
              ></Input>
              <span style={{ lineHeight: 3.8, direction: "ltr" }}>
                .ordable.com
              </span>
            </div>

            <div>
              <Input
                placeholder={t("Store name")}
                size={"large"}
                style={inputStyle}
                onChange={(e) => this.setState({ storeName: e.target.value })}
                defaultValue={registrationData.storeName}
              />
            </div>

            {[1, 2].includes(user.package) && (
              <div style={{ margin: "10px 0px" }}>
                <p
                  className={
                    config.direction === "ltr" ? "text-left" : "text-right"
                  }
                  style={{ fontWeight: "bold" }}
                >
                  {t("What products will you sell?")}{" "}
                  <span
                    style={{
                      color: "grey",
                      float: config.direction === "ltr" ? "right" : "left",
                    }}
                    className="text-left"
                  >
                    {t("Choose")} {user.package === 1 ? "1" : "2"}
                  </span>
                </p>

                <div
                  className={
                    config.direction === "ltr"
                      ? "text-left mb-3"
                      : "text-right mb-3"
                  }
                >
                  <Checkbox
                    onChange={(e) =>
                      this.setState({ enable_produced: e.target.checked })
                    }
                    style={{ width: "100%" }}
                  >
                    {t("Physical products")}{" "}
                    <span
                      style={{
                        color: "grey",
                        fontSize: 12,
                        float: config.direction === "ltr" ? "right" : "left",
                      }}
                    >
                      {t("(eg. food)")}{" "}
                      <Popover
                        placement="bottom"
                        content={
                          <div style={{ maxWidth: 350 }}>
                            <p>{explanations.produced}</p>
                          </div>
                        }
                        title={t("Explanation")}
                      >
                        <QuestionCircleFilled
                          className={
                            config.direction === "ltr" ? "ml-2" : "mr-2"
                          }
                          style={{
                            fontSize: 18,
                            position: "relative",
                            top: -2,
                          }}
                        />
                      </Popover>
                    </span>
                  </Checkbox>
                </div>
                <div
                  className={
                    config.direction === "ltr"
                      ? "text-left mb-3"
                      : "text-right mb-3"
                  }
                >
                  <Checkbox
                    onChange={(e) =>
                      this.setState({ enable_booking: e.target.checked })
                    }
                    style={{ width: "100%" }}
                  >
                    {t("Bookable events")}{" "}
                    <span
                      style={{
                        color: "grey",
                        fontSize: 12,
                        float: config.direction === "ltr" ? "right" : "left",
                      }}
                    >
                      {t("(eg. tickets)")}{" "}
                      <Popover
                        placement="bottom"
                        content={
                          <div style={{ maxWidth: 350 }}>
                            <p>{explanations.booking}</p>
                          </div>
                        }
                        title={t("Explanation")}
                      >
                        <QuestionCircleFilled
                          className={
                            config.direction === "ltr" ? "ml-2" : "mr-2"
                          }
                          style={{
                            fontSize: 18,
                            position: "relative",
                            top: -2,
                          }}
                        />
                      </Popover>
                    </span>
                  </Checkbox>
                </div>
                <div
                  className={
                    config.direction === "ltr"
                      ? "text-left mb-3"
                      : "text-right mb-3"
                  }
                >
                  <Checkbox
                    onChange={(e) =>
                      this.setState({ enable_digital: e.target.checked })
                    }
                    style={{ width: "100%" }}
                  >
                    {t("Digital downloads")}{" "}
                    <span
                      style={{
                        color: "grey",
                        fontSize: 12,
                        float: config.direction === "ltr" ? "right" : "left",
                      }}
                    >
                      {t("(eg. videos)")}{" "}
                      <Popover
                        placement="bottom"
                        content={
                          <div style={{ maxWidth: 350 }}>
                            <p>{explanations.digital}</p>
                          </div>
                        }
                        title={t("Explanation")}
                      >
                        <QuestionCircleFilled
                          className={
                            config.direction === "ltr" ? "ml-2" : "mr-2"
                          }
                          style={{
                            fontSize: 18,
                            position: "relative",
                            top: -2,
                          }}
                        />
                      </Popover>
                    </span>
                  </Checkbox>
                </div>
              </div>
            )}
            <div>
              {t("By clicking on CONTINUE, you agree to the")}{" "}
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => this.setState({ termsOpen: true })}
              >
                {t("terms and conditions")}
              </span>
            </div>
          </Space>
          <Button
            className="py-3 mb-4 w-100"
            style={{
              backgroundColor: this.continueButtonDisabled()
                ? "#ebebeb"
                : "#0099cc",
              color: this.continueButtonDisabled() ? "#d6d6d6" : "white",
              height: 60,
              border: "none",
              marginTop: 30,
            }}
            disabled={this.continueButtonDisabled()}
            onClick={() => {
              this.createStore();
            }}
            loading={this.state.continueButtonLoading}
          >
            {t("CONTINUE")}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registrationData: state.registrationData,
    user: state.auth.user,
    country: state.auth.country,
    config: state.config,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveStepInfo: (data) => dispatch(saveStepInfo(data)),
    goBack: () => dispatch(goBack()),
    storeCreationSuccess: () => dispatch(storeCreationSuccess()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StoreInfo));
